.loader__wrapper{
    position: fixed;
    z-index: 999;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    /* background-color: aqua; */
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.loader{
    width: 85%;
    max-width: 390px;
    margin: -20% auto 0;
}
.loader__logo{
    width: 100%;
    height: auto;
}
.loader__text{
    font-family: Poppins, sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    max-width: 230px;
}
@media screen and (max-height: 500px) and (orientation: landscape) and (min-width: 500px){
    .loader{
        width: 85%;
        max-width: 180px;
        margin: 0 auto;
    }
    .loader__text{
        max-width: 100%;
    }
}

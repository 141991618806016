@font-face {
    font-family: 'Poppins';
    src: url('./Poppins-Regular.woff2') format('woff2'),
        url('./Poppins-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
.your-style {
    font-family: 'Poppins';
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins';
    src: url('./Poppins-Medium.woff2') format('woff2'),
        url('./Poppins-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

.your-style {
    font-family: 'Poppins';
    font-weight: 500;
    font-style: normal;
}
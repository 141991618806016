  
.hide{
    display: none;
}

.welcome__logo_wrapper{
    max-width: 275px;
    width: 100%;
    margin: 0 auto;
}
.welcome__logo{
    width: 100%;
}

.welcome__page_wrapper{
    /* padding-top: 210px; */
    padding-top: 15%;
}
.icon_chief{
    background: url(./img/icon-chief.png) center ;
    display: inline-block;
    width: 24px;
    height: 24px;
}


.welcome__text{
    font-family: Poppins;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    max-width: 330px;
    margin: 66px auto 0;
}
.transparent{
    opacity: 0;
}

.welcome__page{
    
}
.welcome__title{
    color: var(--orange-logo-text-color);
    text-shadow: 5px 5px var(--yellow-logo-textshadow-color);

    font-family: Bellota Text;
    font-size: 50px;
    font-weight: 700;
    line-height: 62.9px;
    letter-spacing: 2px;
    text-align: center;

}
.welcome__page_wrapper{
    
}
.welcome__logo_wrapper{
    
}

.welcome__logo{
    
}
@media screen and (max-height: 500px) and (orientation: landscape) and (min-width: 500px){
    .welcome__logo_wrapper{
        max-width: 140px;
    }
    .welcome__page_wrapper{
        padding-top: 12%;
    }
    .welcome__text{
        max-width: 100%;
        margin-top: 16px;
    }
}
@media screen and (max-height: 350px) and (orientation: landscape) and (min-width: 500px) {
    .welcome__page_wrapper{
        padding-top: 5%;
    }
}
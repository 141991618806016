/* .hide{
    display: none;
} */

.starting__page{
    width: 90%;
    max-width: 333px;
    margin: 111px auto 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
}

.starting__logo{
    width: 100%;
    margin: 0 auto;
}
.starting__logo_wrapper{
    max-width: 333px;
}
.starting__page_text{
    /* font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    text-align: left; */
    margin-top: 25px;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
}

.starting__page_button{
    font-family: Poppins;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 1px;
    text-align: center;
    border: none;
    border-radius: 100px;
    width: 80%;
    max-width: 240px;
    line-height: 60px;
    margin: 32px auto 0;
    background: var(--main-button-red);
    color: #fff;
    cursor: pointer;
    display: inline-block;
    transition: all 0.4s;
  }
  /* .starting__page_button:active,
  .starting__page_button:focus{
    background: var(--main-button-red-active);
  } */
  .starting__page_button:hover{
    background: var(--main-button-red-hover);
  }

  


@media all and (max-width: 1280px) and (max-height: 600px){
    .starting__logo_wrapper{
        max-width: 270px;
        margin: 0 auto;
    }
    .starting__page{
        margin-top: 31px;
    }
    .starting__page_text{
        margin-top: 10px;
    }
    .starting__page_button{
        margin-top: 20px;
    }
}

@media all and (max-width: 768px){
    .starting__page{
        margin-top: 77px;
    }
}
@media all and (max-width: 500px){
    
}
@media all and (max-width: 450px){
    .starting__page{
        margin-top: 30px;
    }
}
@media all and (max-width: 415px){
    
}
@media all and (max-width: 415px) and (max-height: 660px){
    .starting__logo_wrapper{
        max-width: 280px;
        margin: 0 auto;
    }
    
}
@media all and (max-width: 400px){
    
}
@media all and (max-width: 390px){
    
}
@media all and (max-width: 376px) and (max-height: 600px){
    .starting__page_text{
        margin-top: 10px;
    }
}

@media all and (max-width: 360px){
    
}
@media all and (max-width: 350px){

    .starting__logo_wrapper{
        max-width: 200px;
    }
    .starting__page_text{
        max-width: 250px;
        margin-left: auto;
        margin-right: auto;
        font-size: 16px;
        line-height: 19px;
    }
    .starting__page_button{
        line-height: 50px;
        font-size: 20px;
    }
}
@media all and (max-width: 320px){
    
}
@media screen and (max-height: 500px) and (orientation: landscape) and (min-width: 500px) {
    .starting__logo_wrapper{
      display: none;
    }
    .starting__page{
        padding-top: 10vh;
        margin-top: 16px;
    }
  }
@media screen and (max-height: 350px) and (orientation: landscape) and (min-width: 500px) {
    .starting__logo_wrapper{
        display: none;
    }
    .starting__page{
        padding-top: 0;
        margin-top: 16px;
    }
}
